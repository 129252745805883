import { axiosPrivate } from "../react-query/services/axios";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
import { useEffect } from "react";
import jwtDecode from "jwt-decode";

interface JwtPayload {
  exp: number; // Expiry timestamp in seconds since the epoch
  // Add other properties if needed, e.g., userId, roles, etc.
}
const useAxiosPrivate = () => {
  const newAuth = useAuth();
  const refresh = useRefreshToken();
  

  const isTokenExpired = (token:string) => {
    if (!token) return true;
    const { exp } = jwtDecode<JwtPayload>(token);
    return Date.now() >= exp * 1000;
  };

  useEffect(() => {
    const requestInterceptor = axiosPrivate.interceptors.request.use(
      async (config) => {
        if (newAuth?.auth?.accessToken) {
          // Ensure that config.headers is defined before accessing it
          if (config.headers) {      
            if(isTokenExpired(newAuth?.auth.accessToken)){
              const newAccessToken = await refresh();
           
              config.headers['Authorization'] = `Bearer ${newAccessToken}`;
            }
            else{
              config.headers['Authorization'] = `Bearer ${newAuth?.auth.accessToken}`;
            }
          } else {
            // If headers is undefined, initialize it
            (config.headers as any)?.set('Authorization', `Bearer ${newAuth?.auth.accessToken}`);
          }
        }
        return config;
      },
      error => Promise.reject(error)
    );

    const responseInterceptor = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          try {
            const newAccessToken = await refresh();
            prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
            console.log(newAccessToken, 'new_access_token');
            return axiosPrivate(prevRequest);
          } catch (refreshError) {
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.response.eject(responseInterceptor);
      axiosPrivate.interceptors.request.eject(requestInterceptor);
    };
  }, [newAuth?.auth, refresh]);

  return axiosPrivate;
};

export default useAxiosPrivate;
