import { AxiosRequestConfig, AxiosInstance } from "axios";

class APIClient<T, K = undefined> {
  endpoint: string;
  axiosInstance: AxiosInstance;

  constructor(endpoint: string, axiosInstance: AxiosInstance) {
    this.endpoint = endpoint;
    this.axiosInstance = axiosInstance;
  }

  // Get all data
  getAll = () => {
    return this.axiosInstance.get<T[]>(this.endpoint).then((response) => response.data);
  };

  // Create a single data item
  post = (data: K, config?: AxiosRequestConfig) => {
    return this.axiosInstance.post<T>(this.endpoint, data, config).then((response) => response.data);
  };

  // Update data via PUT
  put = (data: K, config?: AxiosRequestConfig) => {
    return this.axiosInstance.put<T>(this.endpoint, data, config).then((response) => response.data);
  };

  // Update data via PATCH
  patch = (data: K, config?: AxiosRequestConfig) => {
    return this.axiosInstance.patch<T>(this.endpoint, data, config).then((response) => response.data);
  };

  // Get a single item
  get = (requestConfig?: AxiosRequestConfig) => {
    return this.axiosInstance.get<T>(this.endpoint, { ...requestConfig }).then((response) => response.data);
  };

  // Delete a single item
  delete = () => {
    return this.axiosInstance.delete<T>(this.endpoint).then((response) => response.data);
  };
}

export { APIClient };
